// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-bbq-index-tsx": () => import("./../../../src/pages/bbq/index.tsx" /* webpackChunkName: "component---src-pages-bbq-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-reservations-tsx": () => import("./../../../src/pages/reservations.tsx" /* webpackChunkName: "component---src-pages-reservations-tsx" */),
  "component---src-pages-restaurants-chigasaki-kitchen-index-tsx": () => import("./../../../src/pages/restaurants/chigasaki-kitchen/index.tsx" /* webpackChunkName: "component---src-pages-restaurants-chigasaki-kitchen-index-tsx" */),
  "component---src-pages-restaurants-chigasaki-kitchen-menu-tsx": () => import("./../../../src/pages/restaurants/chigasaki-kitchen/menu.tsx" /* webpackChunkName: "component---src-pages-restaurants-chigasaki-kitchen-menu-tsx" */),
  "component---src-pages-restaurants-index-tsx": () => import("./../../../src/pages/restaurants/index.tsx" /* webpackChunkName: "component---src-pages-restaurants-index-tsx" */),
  "component---src-pages-restaurants-pizza-osteria-index-tsx": () => import("./../../../src/pages/restaurants/pizza-osteria/index.tsx" /* webpackChunkName: "component---src-pages-restaurants-pizza-osteria-index-tsx" */),
  "component---src-pages-restaurants-pizza-osteria-menu-tsx": () => import("./../../../src/pages/restaurants/pizza-osteria/menu.tsx" /* webpackChunkName: "component---src-pages-restaurants-pizza-osteria-menu-tsx" */)
}

